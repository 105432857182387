import groupBy from 'lodash.groupby';
import isEqual from 'lodash.isequal';
import { match, P } from 'ts-pattern';
import { Segment } from '../common/components/filter/filterbar/types';
import { CONFIDENTIAL_DISPLAY_KEY, HierarchicalFields, Languages } from '../constants';
import {
  ApiMasterDataQueryFilterItem,
  CohortMetricIdType,
  DataFields,
  DataFieldWithDataType,
  DataTypes,
  EmployeeCohortMetricIdType,
  EmployeeDataFields,
  HashCode,
  Operations,
  RegularMetricIdType,
  Segmentation,
  SegmentationByHierarchicalField,
  SegmentationByNonHierarchicalField,
  TimeSelection,
  TimeSelectionType,
} from '../types';
import { getDataFieldWithDataTypeFromKey, getKeyFromDataFieldWithDataType, hashCode } from '../utils';
import { date, format, formatFinQuarter, formatFinYear, formatMonth, formatYear } from '../utils-date';
import { MetricsService } from './metrics/service';
import { QueryEmployeeCohortMetricsParams, QueryRegularMetricsParams } from './metrics/types';
import {
  ApiError,
  ApiMetricResults,
  ApiOverTimeTimeSegmentType,
  ApiTimeSegmentType,
  ApiValueDataType,
  CohortMetricResult,
  DataValue,
  EmployeeCohortMetricResult,
  MetricResult,
  QueryMetricsQuerySuccess,
  RegularMetricResult,
  SQLFilters,
  Timestamp,
} from './types';
import {
  ApiMasterDataType,
  CohortMetricId,
  EmployeeCohortMetricId,
  GroupByFieldInput,
  GroupbyHierarchicalFieldInput,
  GroupingInput,
  QueryCohortMetricsQuery,
  QueryEmployeeCohortMetricsQuery,
  QueryMetricsQuery,
  RegularMetricId,
  TimeSelectionInput,
} from './types-graphql';

const fieldsOfIntValue: DataFieldWithDataType[] = [
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.AGE },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.JOINING_AGE },
];

const isIntValue = (dataFieldWithDataType: DataFieldWithDataType) => {
  return fieldsOfIntValue.deepCompareContains(dataFieldWithDataType);
};

export const singleNonNullValueFilter = <T>(v: T, dataFieldWithDataType: DataFieldWithDataType) => ({
  bigDecimal: null,
  date: null,
  double: null,
  int: isIntValue(dataFieldWithDataType) ? Number(v) : null,
  long: null,
  null: null,
  string: !isIntValue(dataFieldWithDataType) ? v : null,
});

export const singleNullValueFilter = () => ({
  bigDecimal: null,
  date: null,
  double: null,
  int: null,
  long: null,
  null: true,
  string: null,
});

export const toValue = (value: ApiValueDataType): DataValue => {
  return match(value)
    .with({ __typename: 'BigDecimalValue' }, (val) => val.bigDecimal as number)
    .with({ __typename: 'BooleanValue' }, (val) => val.boolean)
    .with({ __typename: 'DateValue' }, (val) => val.date)
    .with({ __typename: 'DoubleValue' }, (val) => val.double)
    .with({ __typename: 'IntValue' }, (val) => val.int)
    .with({ __typename: 'StringValue' }, (val) => val.string)
    .with({ __typename: 'Confidential' }, () => CONFIDENTIAL_DISPLAY_KEY)
    .with({ __typename: 'TimestampValue' }, (val) => val.timestamp as Timestamp)
    .with({ __typename: 'JsonValue' }, (val) => val.json)
    .with(null, (val) => val)
    .exhaustive();
};

export const toTimeSegmentLabel = (segment: ApiTimeSegmentType, locale: Languages): string => {
  return match(segment)
    .with({ __typename: 'CalendarYearMonthlySegment' }, (seg) => formatMonth(date(seg.date).toDate(), locale))
    .with({ __typename: 'CalendarYearQuarterlySegment' }, (seg) => `${seg.quarter.year}-${seg.quarter.quarterOfYear}`)
    .with({ __typename: 'CalendarYearYearlySegment' }, (seg) => formatYear(seg.year, locale))
    .with({ __typename: 'FinancialYearQuarterlySegment' }, (seg) => formatFinQuarter(seg.quarter, locale))
    .with({ __typename: 'FinancialYearYearlySegment' }, (seg) => formatFinYear(seg.year, locale))
    .with({ __typename: 'SingleValueTimeSegment' }, (seg) => `${seg.start}/${seg.end}`)
    .with({ __typename: 'CalendarYearSingleValueByMonthsSegment' }, (seg) => `${seg.start}/${seg.end}`)
    .with({ __typename: 'CalendarYearSingleValueByYearsSegment' }, (seg) => seg.year.toString())
    .with(
      { __typename: 'FinancialYearSingleValueByQuartersSegment' },
      (seg) => `${seg.quarter.year}-${seg.quarter.quarterOfYear}`
    )
    .with({ __typename: 'FinancialYearSingleValueByYearsSegment' }, (seg) => seg.year.toString())
    .exhaustive();
};

const toCohortFrontendType = (success: QueryMetricsQuerySuccess): CohortMetricResult => {
  return {
    type: 'CohortMetricResult',
    metricId: success.metricId as CohortMetricId,
    ...toFrontendType(success),
  };
};

export const toRegularFrontendType = (success: QueryMetricsQuerySuccess): RegularMetricResult => {
  return {
    type: 'RegularMetricResult',
    metricId: success.metricId as RegularMetricId,
    ...toFrontendType(success),
  };
};

export const toEmployeeCohortFrontendType = (success: QueryMetricsQuerySuccess): EmployeeCohortMetricResult => {
  return {
    type: 'EmployeeCohortMetricResult',
    metricId: success.metricId as EmployeeCohortMetricId,
    ...toFrontendType(success),
  };
};

const toFrontendType = (success: QueryMetricsQuerySuccess): Omit<MetricResult, 'metricId' | 'type'> => {
  return {
    segments: success.segments.map((segment) => {
      return {
        groupSegments: segment.groupSegments.map((segments) => {
          return {
            data: toValue(segments.data),
            groupSegment: segments.groupSegment.map(
              (gs: { dataField: DataFields; dataType: DataTypes; value: ApiValueDataType }) => {
                return {
                  dataField: gs.dataField,
                  dataType: gs.dataType,
                  value: toValue(gs.value) as string,
                };
              }
            ),
          };
        }),
        timeSegment: segment.timeSegment,
      };
    }),
    meta: {
      metricSql: success.meta.metricSql,
    },
  };
};

const toHierarchicalSqlInputValueInput = (seg: SegmentationByHierarchicalField): GroupbyHierarchicalFieldInput => {
  return {
    dataField: seg.dataField.dataField,
    dataType: seg.dataField.dataType as unknown as ApiMasterDataType,
    filters:
      seg.filters?.map((v: any[]) =>
        v.map((h: any) => (h === null ? singleNullValueFilter() : singleNonNullValueFilter(h, seg.dataField)))
      ) ?? [], // TODO: empty array might not work
  };
};

const toNonHierarchicalSqlInputValueInput = (seg: SegmentationByNonHierarchicalField): GroupByFieldInput => {
  return {
    filters:
      seg.filters?.map((v: any) =>
        v === null ? singleNullValueFilter() : singleNonNullValueFilter(v, seg.dataField)
      ) ?? null,
    dataField: seg.dataField.dataField,
    dataType: seg.dataField.dataType as unknown as ApiMasterDataType,
  };
};

const toGroupingInput = (segmentations: Segmentation[]): GroupingInput => {
  const groups = segmentations.map((segmentation) => {
    return match(segmentation)
      .with({ type: 'hierarchical' }, (seg) => ({
        byHierarchicalField: toHierarchicalSqlInputValueInput(seg),
        byField: null,
      }))
      .with({ type: 'non-hierarchical' }, (seg) => ({
        byHierarchicalField: null,
        byField: toNonHierarchicalSqlInputValueInput(seg),
      }))
      .exhaustive();
  });
  return { groups };
};

const toTimeSelectionInput = (timeSelection: TimeSelection): TimeSelectionInput => {
  const base = {
    calendarYearMonthly: null,
    calendarYearQuarterly: null,
    calendarYearYearly: null,
    financialYearQuarterly: null,
    financialYearYearly: null,
    singleValue: null,
    singleValueByCalendarMonths: null,
    singleValueByCalendarYears: null,
    singleValueByFinancialQuarters: null,
    singleValueByFinancialYears: null,
  };
  return match(timeSelection)
    .with({ type: TimeSelectionType.CalendarYearMonthly }, (t) => ({ ...base, calendarYearMonthly: t.input }))
    .with({ type: TimeSelectionType.CalendarYearQuarterly }, (t) => ({ ...base, calendarYearQuarterly: t.input }))
    .with({ type: TimeSelectionType.CalendarYearYearly }, (t) => ({ ...base, calendarYearYearly: t.input }))
    .with({ type: TimeSelectionType.FinancialYearQuarterly }, (t) => ({ ...base, financialYearQuarterly: t.input }))
    .with({ type: TimeSelectionType.FinancialYearYearly }, (t) => ({ ...base, financialYearYearly: t.input }))
    .with({ type: TimeSelectionType.CalendarYearSingleValueByMonths }, (t) => ({
      ...base,
      singleValue: { start: format(t.input.start.valueOf()), end: format(t.input.end.valueOf()) },
    })) // TODO: update once the backend is implemented
    .with({ type: TimeSelectionType.CalendarYearSingleValueByYears }, (t) => ({
      ...base,
      singleValueByCalendarYears: t.input,
    }))
    .with({ type: TimeSelectionType.FinancialYearSingleValueByQuarters }, (t) => ({
      ...base,
      singleValueByFinancialQuarters: t.input,
    }))
    .with({ type: TimeSelectionType.FinancialYearSingleValueByYears }, (t) => ({
      ...base,
      singleValueByFinancialYears: t.input,
    }))
    .otherwise(() => {
      throw new Error('TimeSelectionType not supported');
    });
};

// TODO: Ideally there should be a toMetricResultOrError as well.
// Maybe we need {type: MetricIdType, metrics: MetricIdTypeArrays} before we do that
// to match with the correct function.
export const toRegularMetricResultOrError = async (
  metricQueryService: (params: QueryRegularMetricsParams) => Promise<QueryMetricsQuery>,
  metrics: RegularMetricIdType[],
  timeSelection: TimeSelection,
  userFilters?: SQLFilters,
  segmentations?: Segmentation[]
): Promise<Array<RegularMetricResult | Error>> => {
  const grouping: GroupingInput | undefined = segmentations ? toGroupingInput(segmentations) : undefined;
  const response: Promise<Array<RegularMetricResult | Error>> = metricQueryService({
    timeSelection: toTimeSelectionInput(timeSelection),
    metrics,
    userFilters,
    grouping,
  }).then((queryResult: QueryMetricsQuery) => {
    const result = queryResult.queryMetrics as ApiMetricResults | null;
    return (
      result?.results?.map((res) => {
        return match(res)
          .with({ __typename: 'MetricResultFailure' }, (error) => {
            return new Error(error.message);
          })
          .with({ __typename: 'MetricResultSuccess' }, (success) => {
            return toRegularFrontendType(success);
          })
          .exhaustive();
      }) ?? []
    );
  });
  return response;
};

export const toCohortMetricResultOrError = async (
  metricService: MetricsService,
  metrics: CohortMetricIdType[],
  timeSelection: TimeSelection,
  userFilters?: SQLFilters,
  segmentations?: Segmentation[]
): Promise<Array<CohortMetricResult | Error>> => {
  const grouping: GroupingInput | undefined = segmentations ? toGroupingInput(segmentations) : undefined;
  const response: Promise<Array<CohortMetricResult | Error>> = metricService
    .queryCohortMetrics({
      timeSelection: toTimeSelectionInput(timeSelection),
      metrics,
      userFilters,
      grouping,
    })
    .then((queryResult: QueryCohortMetricsQuery) => {
      const result = queryResult.queryCohortMetrics as ApiMetricResults | null;
      return (
        result?.results?.map((res) => {
          return match(res)
            .with({ __typename: 'MetricResultFailure' }, (error) => {
              return new Error(error.message);
            })
            .with({ __typename: 'MetricResultSuccess' }, (success) => {
              return toCohortFrontendType(success);
            })
            .exhaustive();
        }) ?? []
      );
    });
  return response;
};

export const toEmployeeCohortMetricResultOrError = async (
  metricQueryService: (params: QueryEmployeeCohortMetricsParams) => Promise<QueryEmployeeCohortMetricsQuery>,
  metrics: EmployeeCohortMetricIdType[],
  timeSelection: TimeSelection,
  userCohortFilters?: SQLFilters,
  segmentations?: Segmentation[]
): Promise<Array<EmployeeCohortMetricResult | Error>> => {
  const grouping: GroupingInput | undefined = segmentations ? toGroupingInput(segmentations) : undefined;
  const response: Promise<Array<EmployeeCohortMetricResult | Error>> = metricQueryService({
    timeSelection: toTimeSelectionInput(timeSelection),
    metrics,
    userCohortFilters,
    grouping,
  }).then((queryResult: QueryEmployeeCohortMetricsQuery) => {
    const result = queryResult.queryEmployeeCohortMetrics as ApiMetricResults | null;
    return (
      result?.results?.map((res) => {
        return match(res)
          .with({ __typename: 'MetricResultFailure' }, (error) => {
            return new Error(error.message);
          })
          .with({ __typename: 'MetricResultSuccess' }, (success) => {
            return toEmployeeCohortFrontendType(success);
          })
          .exhaustive();
      }) ?? []
    );
  });
  return response;
};

export const combineFilters = (filters: ApiMasterDataQueryFilterItem[]) => {
  return filters.reduce<ApiMasterDataQueryFilterItem[]>((acc, filter) => {
    const existingItem = acc.find(
      (item) =>
        item.property === filter.property &&
        item.operation === filter.operation &&
        item.dataType === filter.dataType &&
        item.operation !== Operations.NOT_EQUAL &&
        filter.operation !== Operations.NOT_EQUAL &&
        item.dontCombine !== true &&
        filter.dontCombine !== true
    );

    if (existingItem) {
      const values = Array.from(new Set([existingItem.values, filter.values].flat()));
      return [
        ...acc.filter((f) => !isEqual(f, existingItem)),
        {
          ...existingItem,
          values,
        },
      ];
    } else {
      return [...acc, filter];
    }
  }, []);
};

export const isHierarchical = (filterProperty: DataFieldWithDataType): boolean => {
  return Array.from(HierarchicalFields).some((e) => isEqual(filterProperty, e));
};

export const toSegmentation = (filters: Segment[]): Segmentation[] | undefined => {
  if (filters.length === 0) {
    return undefined;
  }
  return Object.entries(
    groupBy(filters, (f) =>
      getKeyFromDataFieldWithDataType({ dataType: f.dataType, dataField: f.property as DataFields })
    )
  ).map(([key, f]) => {
    const dataFieldWithDataType = getDataFieldWithDataTypeFromKey(key);
    return isHierarchical(dataFieldWithDataType)
      ? {
          type: 'hierarchical',
          dataField: dataFieldWithDataType,
          filters: f.map((fv) => fv.values.flatMap((v) => v as string)),
        }
      : {
          type: 'non-hierarchical',
          dataField: dataFieldWithDataType,
          filters: f.map((fv) => fv.values[0] as unknown as DataValue),
        };
  });
};

export const handleGQLErrors = async <T>(result: Promise<T>): Promise<T> => {
  return result.catch((error) => {
    // tslint:disable-next-line:no-console
    const errorReferences: string[] | null = error?.response?.errors?.map((e: any) => e.extensions?.errorReference);
    const apiError: ApiError = {
      name: error.name,
      message: `Couldn't fetch data. Error ref: ${errorReferences?.join(',') ?? 'unknown'}`,
      errorReferences,
    };
    return Promise.reject(apiError);
  });
};

export const compareApiOverTimeTimeSegmentType = (ts1: ApiOverTimeTimeSegmentType, ts2: ApiOverTimeTimeSegmentType) =>
  match([ts1, ts2])
    .with(
      [
        { __typename: 'CalendarYearMonthlySegment', date: P.string },
        { __typename: 'CalendarYearMonthlySegment', date: P.string },
      ],
      ([t1, t2]) => t1.date === t2.date
    )
    .with(
      [
        { __typename: 'CalendarYearYearlySegment', year: P.number },
        { __typename: 'CalendarYearYearlySegment', year: P.number },
      ],
      ([t1, t2]) => t1.year === t2.year
    )
    .with(
      [
        { __typename: 'FinancialYearQuarterlySegment', quarter: { quarterOfYear: P.number, year: P.number } },
        { __typename: 'FinancialYearQuarterlySegment', quarter: { quarterOfYear: P.number, year: P.number } },
      ],
      ([t1, t2]) => t1.quarter.year === t2.quarter.year && t1.quarter.quarterOfYear === t2.quarter.quarterOfYear
    )
    .with(
      [
        { __typename: 'FinancialYearYearlySegment', year: P.number },
        { __typename: 'FinancialYearYearlySegment', year: P.number },
      ],
      ([t1, t2]) => t1.year === t2.year
    )
    .otherwise(() => {
      throw new Error('Invalid time segment type');
    });

export const getFilterKey = (dimension: DataFieldWithDataType, values: any[]): HashCode => {
  return hashCode(`${getKeyFromDataFieldWithDataType(dimension)}-${values.join('-').replace(/\s+/g, '') ?? 'NA'}`);
};
