import { DataValue } from './api/types';
import {
  CalendarYearMonthlyTimeSelectionInput,
  CalendarYearQuarterlyTimeSelectionInput,
  CalendarYearSingleValueByMonthsSegment,
  CalendarYearSingleValueByYearsSegment,
  CalendarYearYearlyTimeSelectionInput,
  CohortMetricId,
  EmployeeCohortMetricId,
  FinancialYearQuarterlyTimeSelectionInput,
  FinancialYearSingleValueByQuartersSegment,
  FinancialYearSingleValueByYearsSegment,
  FinancialYearYearlyTimeSelectionInput,
  RegularMetricId,
} from './api/types-graphql';
import { Filter } from './common/components/filter/filterbar/types';

export type HashCode = { type: 'hashCode'; hash: number };

export enum FormatTypes {
  PERCENTAGE = 'PERCENTAGE',
  COUNT = 'COUNT',
  NUMBER = 'NUMBER',
  SENSITIVE_NUMBER = 'SENSITIVE_NUMBER',
  CURRENCY_INDIVIDUAL = 'CURRENCY_INDIVIDUAL',
  CURRENCY_AGGREGATE = 'CURRENCY_AGGREGATE',
  EXACT_NUMBER = 'EXACT_NUMBER',
  FTE = 'FTE',
  HOURS = 'HOURS',
  LEAVES_TAKEN = 'LEAVES_TAKEN',
  // Used in DataView for eg where we want commaSeparation but don't wanna lose precision
  FLOATING_NUMBER_1 = 'FLOATING_NUMBER_1',
  FLOATING_NUMBER_2 = 'FLOATING_NUMBER_2',
}

export type CURRENCY_FORMAT_TYPE = FormatTypes.CURRENCY_AGGREGATE | FormatTypes.CURRENCY_INDIVIDUAL;
export class CurrencyBucket {
  constructor(readonly value: number, readonly delimiter: string) {}
}

export interface MetricFormatOptions {
  currency?: string;
  formatType?: CURRENCY_FORMAT_TYPE;
}

/**
 * Time value in milliseconds since midnight, January 1, 1970 UTC. Result of calling valueOf() on moment object.
 */
export type TimestampUTC = number;
export type DateAsYYYYMMDD = string;

export interface RegularMetricIdType {
  type: 'RegularMetricId';
  value: RegularMetricId;
}
export interface CohortMetricIdType {
  type: 'CohortMetricId';
  value: CohortMetricId;
}

export interface EmployeeCohortMetricIdType {
  type: 'EmployeeCohortMetricId';
  value: EmployeeCohortMetricId;
}

export type MetricIdType = RegularMetricIdType | CohortMetricIdType | EmployeeCohortMetricIdType;

// TODO: Maybe This needs to change
// Maybe I need something like {type: EmployeeCohortMetricIds, value: EmployeeCohortMetricId[]}
export type MetricIdTypeArrays = RegularMetricIdType[] | CohortMetricIdType[] | EmployeeCohortMetricIdType[];

export enum DataTypes {
  EMPLOYEE = 'EMPLOYEE',
  APPLICATION = 'APPLICATION',
  JOB = 'JOB',
  OFFER = 'OFFER',
  RECRUITERJOBS = 'RECRUITERJOBS',
  QUESTIONANSWER = 'QUESTIONANSWER',
  TIMEANDATTENDANCEMONTHLY = 'TIMEANDATTENDANCEMONTHLY',
  PAYROLL = 'PAYROLL',
  EVALUATION = 'EVALUATION',
  JOINERS_VIEW = 'JOINERS_VIEW',
  FINANCIALMETRICS = 'FINANCIALMETRICS',
  INTERVIEW = 'INTERVIEW',
  MANAGERENPS = 'MANAGERENPS',
}

export enum EMPLOYEE_COHORT_TYPES {
  HC_SIMULATION = 'hcsim',
}

export enum EmployeeDataFields {
  EFFECTIVE_DATE = 'EFFECTIVE_DATE',
  EFFECTIVE_MONTH = 'EFFECTIVE_MONTH', // This field doesn't actually exist on the employee table
  START_DATE = 'START_DATE',
  TERM_DATE = 'TERM_DATE',
  START_DATE_NORMALIZED = 'START_DATE_NORMALIZED',
  START_DATE_NORMALIZED_YEAR_MONTH = 'START_DATE_NORMALIZED_YEAR_MONTH',
  TERM_DATE_NORMALIZED = 'TERM_DATE_NORMALIZED',
  TERM_DATE_NORMALIZED_YEAR_MONTH = 'TERM_DATE_NORMALIZED_YEAR_MONTH',
  EFFECTIVE_LEAVER_DATE = 'EFFECTIVE_LEAVER_DATE',
  EFFECTIVE_LEAVER_DATE_NORMALIZED = 'EFFECTIVE_LEAVER_DATE_NORMALIZED',
  DATE_OF_BIRTH_YEAR_MONTH = 'DATE_OF_BIRTH_YEAR_MONTH',
  CALENDAR_YEAR = 'CALENDAR_YEAR',
  CALENDAR_QUARTER = 'CALENDAR_QUARTER',
  FINANCIAL_YEAR = 'FINANCIAL_YEAR',
  FINANCIAL_QUARTER = 'FINANCIAL_QUARTER',
  EMPLOYMENT_TEMPORALITY = 'EMPLOYMENT_TEMPORALITY',
  EMPLOYEE_ID = 'EMPLOYEE_ID',
  MEMBER_ID = 'MEMBER_ID',
  FULL_NAME = 'FULL_NAME',
  FIRST_NAME = 'FIRST_NAME',
  LAST_NAME = 'LAST_NAME',
  LOCAL_FULL_NAME = 'LOCAL_FULL_NAME',
  PREFERRED_NAME = 'PREFERRED_NAME',
  COMPANY_EMAIL = 'COMPANY_EMAIL',
  GENDER = 'GENDER',
  DATE_OF_BIRTH = 'DATE_OF_BIRTH',
  MANAGER_ID = 'MANAGER_ID',
  MANAGER_EMAIL = 'MANAGER_EMAIL',
  NATIONALITY_HIERARCHICAL = 'NATIONALITY_HIERARCHICAL',
  NATIONALITY_HIERARCHICAL_LEVEL_1 = 'NATIONALITY_HIERARCHICAL_LEVEL_1',
  NATIONALITY_HIERARCHICAL_LEVEL_2 = 'NATIONALITY_HIERARCHICAL_LEVEL_2',
  NATIONALITY_HIERARCHICAL_LEVEL_3 = 'NATIONALITY_HIERARCHICAL_LEVEL_3',
  NATIONALITY_HIERARCHICAL_LEVEL_4 = 'NATIONALITY_HIERARCHICAL_LEVEL_4',
  NATIONALITY_HIERARCHICAL_LEVEL_5 = 'NATIONALITY_HIERARCHICAL_LEVEL_5',
  NATIONALITY_HIERARCHICAL_LEVEL_6 = 'NATIONALITY_HIERARCHICAL_LEVEL_6',
  NATIONALITY_HIERARCHICAL_LEVEL_7 = 'NATIONALITY_HIERARCHICAL_LEVEL_7',
  NATIONALITY_HIERARCHICAL_LEVEL_8 = 'NATIONALITY_HIERARCHICAL_LEVEL_8',
  NATIONALITY_HIERARCHICAL_LEVEL_9 = 'NATIONALITY_HIERARCHICAL_LEVEL_9',
  NATIONALITY_HIERARCHICAL_LEVEL_10 = 'NATIONALITY_HIERARCHICAL_LEVEL_10',
  PERFORMANCE_CYCLE = 'PERFORMANCE_CYCLE',
  ORGANIZATION = 'ORGANIZATION',
  ORGANIZATION_LEVEL_1 = 'ORGANIZATION_LEVEL_1',
  ORGANIZATION_LEVEL_2 = 'ORGANIZATION_LEVEL_2',
  ORGANIZATION_LEVEL_3 = 'ORGANIZATION_LEVEL_3',
  ORGANIZATION_LEVEL_4 = 'ORGANIZATION_LEVEL_4',
  ORGANIZATION_LEVEL_5 = 'ORGANIZATION_LEVEL_5',
  ORGANIZATION_LEVEL_6 = 'ORGANIZATION_LEVEL_6',
  ORGANIZATION_LEVEL_7 = 'ORGANIZATION_LEVEL_7',
  ORGANIZATION_LEVEL_8 = 'ORGANIZATION_LEVEL_8',
  ORGANIZATION_LEVEL_9 = 'ORGANIZATION_LEVEL_9',
  ORGANIZATION_LEVEL_10 = 'ORGANIZATION_LEVEL_10',
  PRE_MERGER_ORGANIZATION = 'PRE_MERGER_ORGANIZATION',
  PRE_MERGER_ORGANIZATION_LEVEL_1 = 'PRE_MERGER_ORGANIZATION_LEVEL_1',
  PRE_MERGER_ORGANIZATION_LEVEL_2 = 'PRE_MERGER_ORGANIZATION_LEVEL_2',
  PRE_MERGER_ORGANIZATION_LEVEL_3 = 'PRE_MERGER_ORGANIZATION_LEVEL_3',
  PRE_MERGER_ORGANIZATION_LEVEL_4 = 'PRE_MERGER_ORGANIZATION_LEVEL_4',
  PRE_MERGER_ORGANIZATION_LEVEL_5 = 'PRE_MERGER_ORGANIZATION_LEVEL_5',
  PRE_MERGER_ORGANIZATION_LEVEL_6 = 'PRE_MERGER_ORGANIZATION_LEVEL_6',
  PRE_MERGER_ORGANIZATION_LEVEL_7 = 'PRE_MERGER_ORGANIZATION_LEVEL_7',
  PRE_MERGER_ORGANIZATION_LEVEL_8 = 'PRE_MERGER_ORGANIZATION_LEVEL_8',
  PRE_MERGER_ORGANIZATION_LEVEL_9 = 'PRE_MERGER_ORGANIZATION_LEVEL_9',
  PRE_MERGER_ORGANIZATION_LEVEL_10 = 'PRE_MERGER_ORGANIZATION_LEVEL_10',
  LOCATION = 'LOCATION',
  LOCATION_LEVEL_1 = 'LOCATION_LEVEL_1',
  LOCATION_LEVEL_2 = 'LOCATION_LEVEL_2',
  LOCATION_LEVEL_3 = 'LOCATION_LEVEL_3',
  LOCATION_LEVEL_4 = 'LOCATION_LEVEL_4',
  LOCATION_LEVEL_5 = 'LOCATION_LEVEL_5',
  LOCATION_LEVEL_6 = 'LOCATION_LEVEL_6',
  LOCATION_LEVEL_7 = 'LOCATION_LEVEL_7',
  LOCATION_LEVEL_8 = 'LOCATION_LEVEL_8',
  LOCATION_LEVEL_9 = 'LOCATION_LEVEL_9',
  LOCATION_LEVEL_10 = 'LOCATION_LEVEL_10',
  FUNCTION = 'FUNCTION',
  FUNCTION_LEVEL_1 = 'FUNCTION_LEVEL_1',
  FUNCTION_LEVEL_2 = 'FUNCTION_LEVEL_2',
  FUNCTION_LEVEL_3 = 'FUNCTION_LEVEL_3',
  FUNCTION_LEVEL_4 = 'FUNCTION_LEVEL_4',
  FUNCTION_LEVEL_5 = 'FUNCTION_LEVEL_5',
  FUNCTION_LEVEL_6 = 'FUNCTION_LEVEL_6',
  FUNCTION_LEVEL_7 = 'FUNCTION_LEVEL_7',
  FUNCTION_LEVEL_8 = 'FUNCTION_LEVEL_8',
  FUNCTION_LEVEL_9 = 'FUNCTION_LEVEL_9',
  FUNCTION_LEVEL_10 = 'FUNCTION_LEVEL_10',
  EMPLOYING_ENTITY = 'EMPLOYING_ENTITY',
  REGIONAL_OR_LOCAL = 'REGIONAL_OR_LOCAL',
  EMPLOYMENT_TYPE = 'EMPLOYMENT_TYPE',
  EMPLOYMENT_TYPE_LEVEL_1 = 'EMPLOYMENT_TYPE_LEVEL_1',
  EMPLOYMENT_TYPE_LEVEL_2 = 'EMPLOYMENT_TYPE_LEVEL_2',
  EMPLOYMENT_TYPE_LEVEL_3 = 'EMPLOYMENT_TYPE_LEVEL_3',
  EMPLOYMENT_TYPE_LEVEL_4 = 'EMPLOYMENT_TYPE_LEVEL_4',
  EMPLOYMENT_TYPE_LEVEL_5 = 'EMPLOYMENT_TYPE_LEVEL_5',
  EMPLOYMENT_TYPE_LEVEL_6 = 'EMPLOYMENT_TYPE_LEVEL_6',
  EMPLOYMENT_TYPE_LEVEL_7 = 'EMPLOYMENT_TYPE_LEVEL_7',
  EMPLOYMENT_TYPE_LEVEL_8 = 'EMPLOYMENT_TYPE_LEVEL_8',
  EMPLOYMENT_TYPE_LEVEL_9 = 'EMPLOYMENT_TYPE_LEVEL_9',
  EMPLOYMENT_TYPE_LEVEL_10 = 'EMPLOYMENT_TYPE_LEVEL_10',
  INTERNAL_JOB_GRADE = 'INTERNAL_JOB_GRADE',
  JOB_GRADE = 'JOB_GRADE',
  JOB_GRADE_LEVEL_1 = 'JOB_GRADE_LEVEL_1',
  JOB_GRADE_LEVEL_2 = 'JOB_GRADE_LEVEL_2',
  JOB_GRADE_LEVEL_3 = 'JOB_GRADE_LEVEL_3',
  JOB_GRADE_LEVEL_4 = 'JOB_GRADE_LEVEL_4',
  JOB_GRADE_LEVEL_5 = 'JOB_GRADE_LEVEL_5',
  JOB_GRADE_LEVEL_6 = 'JOB_GRADE_LEVEL_6',
  JOB_GRADE_LEVEL_7 = 'JOB_GRADE_LEVEL_7',
  JOB_GRADE_LEVEL_8 = 'JOB_GRADE_LEVEL_8',
  JOB_GRADE_LEVEL_9 = 'JOB_GRADE_LEVEL_9',
  JOB_GRADE_LEVEL_10 = 'JOB_GRADE_LEVEL_10',
  INTERNAL_JOB_TITLE = 'INTERNAL_JOB_TITLE',
  JOB_TITLE = 'JOB_TITLE',
  RECRUITMENT_CATEGORY = 'RECRUITMENT_CATEGORY',
  RECRUITMENT_CATEGORY_HIERARCHICAL = 'RECRUITMENT_CATEGORY_HIERARCHICAL',
  RECRUITMENT_CATEGORY_HIERARCHICAL_LEVEL_1 = 'RECRUITMENT_CATEGORY_HIERARCHICAL_LEVEL_1',
  RECRUITMENT_CATEGORY_HIERARCHICAL_LEVEL_2 = 'RECRUITMENT_CATEGORY_HIERARCHICAL_LEVEL_2',
  RECRUITMENT_CATEGORY_HIERARCHICAL_LEVEL_3 = 'RECRUITMENT_CATEGORY_HIERARCHICAL_LEVEL_3',
  RECRUITMENT_CATEGORY_HIERARCHICAL_LEVEL_4 = 'RECRUITMENT_CATEGORY_HIERARCHICAL_LEVEL_4',
  RECRUITMENT_CATEGORY_HIERARCHICAL_LEVEL_5 = 'RECRUITMENT_CATEGORY_HIERARCHICAL_LEVEL_5',
  RECRUITMENT_CATEGORY_HIERARCHICAL_LEVEL_6 = 'RECRUITMENT_CATEGORY_HIERARCHICAL_LEVEL_6',
  RECRUITMENT_CATEGORY_HIERARCHICAL_LEVEL_7 = 'RECRUITMENT_CATEGORY_HIERARCHICAL_LEVEL_7',
  RECRUITMENT_CATEGORY_HIERARCHICAL_LEVEL_8 = 'RECRUITMENT_CATEGORY_HIERARCHICAL_LEVEL_8',
  RECRUITMENT_CATEGORY_HIERARCHICAL_LEVEL_9 = 'RECRUITMENT_CATEGORY_HIERARCHICAL_LEVEL_9',
  RECRUITMENT_CATEGORY_HIERARCHICAL_LEVEL_10 = 'RECRUITMENT_CATEGORY_HIERARCHICAL_LEVEL_10',
  WORK_CONSIDERATION = 'WORK_CONSIDERATION',
  SOCIAL_TYPE = 'SOCIAL_TYPE',
  SUPERIOR_ID = 'SUPERIOR_ID',
  CAREER_LADDER = 'CAREER_LADDER',
  ONBOARDING_SURVEY_SCORE = 'ONBOARDING_SURVEY_SCORE',
  ONBOARDING_SURVEY_SCORE_GROUP = 'ONBOARDING_SURVEY_SCORE_GROUP',
  PERFORMANCE_REGULAR_CYCLE = 'PERFORMANCE_REGULAR_CYCLE',
  PERFORMANCE_BONUS_CYCLE = 'PERFORMANCE_BONUS_CYCLE',
  MANAGER_FEEDBACK = 'MANAGER_FEEDBACK',
  TOTAL_AVERAGE_SCORE = 'TOTAL_AVERAGE_SCORE',
  TOTAL_AVERAGE_SCORE_QUARTILE = 'TOTAL_AVERAGE_SCORE_QUARTILE',
  COMMUNICATION_SKILL_SCORE = 'COMMUNICATION_SKILL_SCORE',
  COMMUNICATION_SKILL_SCORE_QUARTILE = 'COMMUNICATION_SKILL_SCORE_QUARTILE',
  GENERAL_COGNITIVE_ABILITY_SCORE = 'GENERAL_COGNITIVE_ABILITY_SCORE',
  GENERAL_COGNITIVE_ABILITY_SCORE_QUARTILE = 'GENERAL_COGNITIVE_ABILITY_SCORE_QUARTILE',
  ROLE_RELATED_KNOWLEDGE_SCORE = 'ROLE_RELATED_KNOWLEDGE_SCORE',
  ROLE_RELATED_KNOWLEDGE_SCORE_QUARTILE = 'ROLE_RELATED_KNOWLEDGE_SCORE_QUARTILE',
  REGRET_ATTRITION = 'REGRET_ATTRITION',
  VOLUNTARY_OR_INVOLUNTARY_ATTRITION = 'VOLUNTARY_OR_INVOLUNTARY_ATTRITION',
  PERFORMANCE_RATING = 'PERFORMANCE_RATING',
  LOCAL_OR_NON_LOCAL = 'LOCAL_OR_NON_LOCAL',
  BASE_SALARY_CURRENCY = 'BASE_SALARY_CURRENCY',
  BASE_SALARY = 'BASE_SALARY',
  BASE_SALARY_FREQUENCY = 'BASE_SALARY_FREQUENCY',
  BASE_SALARY_PER_YEAR = 'BASE_SALARY_PER_YEAR',
  REVENUE_SALARY = 'REVENUE_SALARY',
  REVENUE_SALARY_CURRENCY = 'REVENUE_SALARY_CURRENCY',
  LOCAL_REVENUE_SALARY = 'LOCAL_REVENUE_SALARY',
  ICR = 'ICR',
  ANNUAL_CASH_BONUS_1_CURRENCY = 'ANNUAL_CASH_BONUS_1_CURRENCY',
  ANNUAL_CASH_BONUS_1 = 'ANNUAL_CASH_BONUS_1',
  AGE_GROUP = 'AGE_GROUP',
  TENURE_GROUP = 'TENURE_GROUP',
  VERSION_ID = 'VERSION_ID',
  LOCAL_FIRST_NAME = 'LOCAL_FIRST_NAME',
  LOCAL_LAST_NAME = 'LOCAL_LAST_NAME',
  AGE = 'AGE',
  MARITAL_STATUS = 'MARITAL_STATUS',
  COMPANY_COUNTRY = 'COMPANY_COUNTRY',
  STANDARDIZED_BASE_SALARY = 'STANDARDIZED_BASE_SALARY',
  STANDARDIZED_BASE_SALARY_PER_YEAR = 'STANDARDIZED_BASE_SALARY_PER_YEAR',
  LOCAL_SALARY_CURRENCY = 'LOCAL_SALARY_CURRENCY',
  LOCAL_SALARY = 'LOCAL_SALARY',
  COST_CENTER = 'COST_CENTER',
  DAYS_IN_COMPANY = 'DAYS_IN_COMPANY',
  POSITION = 'POSITION',
  POSITION_LEVEL_1 = 'POSITION_LEVEL_1',
  POSITION_LEVEL_2 = 'POSITION_LEVEL_2',
  POSITION_LEVEL_3 = 'POSITION_LEVEL_3',
  POSITION_LEVEL_4 = 'POSITION_LEVEL_4',
  POSITION_LEVEL_5 = 'POSITION_LEVEL_5',
  POSITION_LEVEL_6 = 'POSITION_LEVEL_6',
  POSITION_LEVEL_7 = 'POSITION_LEVEL_7',
  POSITION_LEVEL_8 = 'POSITION_LEVEL_8',
  POSITION_LEVEL_9 = 'POSITION_LEVEL_9',
  POSITION_LEVEL_10 = 'POSITION_LEVEL_10',
  LOCAL_FIRST_NAME_PRONUNCIATION = 'LOCAL_FIRST_NAME_PRONUNCIATION',
  LOCAL_LAST_NAME_PRONUNCIATION = 'LOCAL_LAST_NAME_PRONUNCIATION',
  LOCAL_FULL_NAME_PRONUNCIATION = 'LOCAL_FULL_NAME_PRONUNCIATION',
  LOCAL_FIRST_NAME_PRONUNCIATION_2 = 'LOCAL_FIRST_NAME_PRONUNCIATION_2',
  LOCAL_LAST_NAME_PRONUNCIATION_2 = 'LOCAL_LAST_NAME_PRONUNCIATION_2',
  LOCAL_FULL_NAME_PRONUNCIATION_2 = 'LOCAL_FULL_NAME_PRONUNCIATION_2',
  CUSTOM_FILTER_1 = 'CUSTOM_FILTER_1',
  CUSTOM_FILTER_2 = 'CUSTOM_FILTER_2',
  CUSTOM_FILTER_3 = 'CUSTOM_FILTER_3',
  CUSTOM_FILTER_4 = 'CUSTOM_FILTER_4',
  CUSTOM_FILTER_5 = 'CUSTOM_FILTER_5',
  CUSTOM_FILTER_6 = 'CUSTOM_FILTER_6',
  CUSTOM_FILTER_7 = 'CUSTOM_FILTER_7',
  CUSTOM_FILTER_8 = 'CUSTOM_FILTER_8',
  CUSTOM_FILTER_9 = 'CUSTOM_FILTER_9',
  CUSTOM_FILTER_10 = 'CUSTOM_FILTER_10',
  CUSTOM_FILTER_11 = 'CUSTOM_FILTER_11',
  CUSTOM_FILTER_12 = 'CUSTOM_FILTER_12',
  CUSTOM_FILTER_13 = 'CUSTOM_FILTER_13',
  CUSTOM_FILTER_14 = 'CUSTOM_FILTER_14',
  CUSTOM_FILTER_15 = 'CUSTOM_FILTER_15',
  CUSTOM_FILTER_16 = 'CUSTOM_FILTER_16',
  CUSTOM_FILTER_17 = 'CUSTOM_FILTER_17',
  CUSTOM_FILTER_18 = 'CUSTOM_FILTER_18',
  CUSTOM_FILTER_19 = 'CUSTOM_FILTER_19',
  CUSTOM_FILTER_20 = 'CUSTOM_FILTER_20',
  JOINING_AGE = 'JOINING_AGE',
  JOINING_AGE_GROUP = 'JOINING_AGE_GROUP',
  ATTRITION_SCORE = 'ATTRITION_SCORE',
  CAREER_CHANGE_INTENTION = 'CAREER_CHANGE_INTENTION',
  INTERNAL_TRANSFER_INTENTION = 'INTERNAL_TRANSFER_INTENTION',
  PULSE_SURVEY_CYCLE_1 = 'PULSE_SURVEY_CYCLE_1',
  PULSE_SURVEY_CYCLE_2 = 'PULSE_SURVEY_CYCLE_2',
  PULSE_SURVEY_CYCLE_3 = 'PULSE_SURVEY_CYCLE_3',
  PULSE_SURVEY_SCORE_1 = 'PULSE_SURVEY_SCORE_1',
  PULSE_SURVEY_SCORE_2 = 'PULSE_SURVEY_SCORE_2',
  CLIENT_EFFECTIVE_DATE = 'CLIENT_EFFECTIVE_DATE',
  MANAGER_OR_IC = 'MANAGER_OR_IC',
  CAREER_COURSE = 'CAREER_COURSE',
  TOTAL_COMPENSATION_CURRENCY = 'TOTAL_COMPENSATION_CURRENCY',
  TOTAL_COMPENSATION = 'TOTAL_COMPENSATION',
  PND_VALUE = 'PND_VALUE',
  CUSTOM_FIELD_1 = 'CUSTOM_FIELD_1',
  CUSTOM_FIELD_1_LEVEL_1 = 'CUSTOM_FIELD_1_LEVEL_1',
  CUSTOM_FIELD_1_LEVEL_2 = 'CUSTOM_FIELD_1_LEVEL_2',
  CUSTOM_FIELD_1_LEVEL_3 = 'CUSTOM_FIELD_1_LEVEL_3',
  CUSTOM_FIELD_1_LEVEL_4 = 'CUSTOM_FIELD_1_LEVEL_4',
  CUSTOM_FIELD_1_LEVEL_5 = 'CUSTOM_FIELD_1_LEVEL_5',
  CUSTOM_FIELD_1_LEVEL_6 = 'CUSTOM_FIELD_1_LEVEL_6',
  CUSTOM_FIELD_1_LEVEL_7 = 'CUSTOM_FIELD_1_LEVEL_7',
  CUSTOM_FIELD_1_LEVEL_8 = 'CUSTOM_FIELD_1_LEVEL_8',
  CUSTOM_FIELD_1_LEVEL_9 = 'CUSTOM_FIELD_1_LEVEL_9',
  CUSTOM_FIELD_1_LEVEL_10 = 'CUSTOM_FIELD_1_LEVEL_10',
  CUSTOM_FIELD_2 = 'CUSTOM_FIELD_2',
  CUSTOM_FIELD_2_LEVEL_1 = 'CUSTOM_FIELD_2_LEVEL_1',
  CUSTOM_FIELD_2_LEVEL_2 = 'CUSTOM_FIELD_2_LEVEL_2',
  CUSTOM_FIELD_2_LEVEL_3 = 'CUSTOM_FIELD_2_LEVEL_3',
  CUSTOM_FIELD_2_LEVEL_4 = 'CUSTOM_FIELD_2_LEVEL_4',
  CUSTOM_FIELD_2_LEVEL_5 = 'CUSTOM_FIELD_2_LEVEL_5',
  CUSTOM_FIELD_2_LEVEL_6 = 'CUSTOM_FIELD_2_LEVEL_6',
  CUSTOM_FIELD_2_LEVEL_7 = 'CUSTOM_FIELD_2_LEVEL_7',
  CUSTOM_FIELD_2_LEVEL_8 = 'CUSTOM_FIELD_2_LEVEL_8',
  CUSTOM_FIELD_2_LEVEL_9 = 'CUSTOM_FIELD_2_LEVEL_9',
  CUSTOM_FIELD_2_LEVEL_10 = 'CUSTOM_FIELD_2_LEVEL_10',
  CUSTOM_FIELD_3 = 'CUSTOM_FIELD_3',
  CUSTOM_FIELD_3_LEVEL_1 = 'CUSTOM_FIELD_3_LEVEL_1',
  CUSTOM_FIELD_3_LEVEL_2 = 'CUSTOM_FIELD_3_LEVEL_2',
  CUSTOM_FIELD_3_LEVEL_3 = 'CUSTOM_FIELD_3_LEVEL_3',
  CUSTOM_FIELD_3_LEVEL_4 = 'CUSTOM_FIELD_3_LEVEL_4',
  CUSTOM_FIELD_3_LEVEL_5 = 'CUSTOM_FIELD_3_LEVEL_5',
  CUSTOM_FIELD_3_LEVEL_6 = 'CUSTOM_FIELD_3_LEVEL_6',
  CUSTOM_FIELD_3_LEVEL_7 = 'CUSTOM_FIELD_3_LEVEL_7',
  CUSTOM_FIELD_3_LEVEL_8 = 'CUSTOM_FIELD_3_LEVEL_8',
  CUSTOM_FIELD_3_LEVEL_9 = 'CUSTOM_FIELD_3_LEVEL_9',
  CUSTOM_FIELD_3_LEVEL_10 = 'CUSTOM_FIELD_3_LEVEL_10',
  CUSTOM_FIELD_4 = 'CUSTOM_FIELD_4',
  CUSTOM_FIELD_4_LEVEL_1 = 'CUSTOM_FIELD_4_LEVEL_1',
  CUSTOM_FIELD_4_LEVEL_2 = 'CUSTOM_FIELD_4_LEVEL_2',
  CUSTOM_FIELD_4_LEVEL_3 = 'CUSTOM_FIELD_4_LEVEL_3',
  CUSTOM_FIELD_4_LEVEL_4 = 'CUSTOM_FIELD_4_LEVEL_4',
  CUSTOM_FIELD_4_LEVEL_5 = 'CUSTOM_FIELD_4_LEVEL_5',
  CUSTOM_FIELD_4_LEVEL_6 = 'CUSTOM_FIELD_4_LEVEL_6',
  CUSTOM_FIELD_4_LEVEL_7 = 'CUSTOM_FIELD_4_LEVEL_7',
  CUSTOM_FIELD_4_LEVEL_8 = 'CUSTOM_FIELD_4_LEVEL_8',
  CUSTOM_FIELD_4_LEVEL_9 = 'CUSTOM_FIELD_4_LEVEL_9',
  CUSTOM_FIELD_4_LEVEL_10 = 'CUSTOM_FIELD_4_LEVEL_10',
  CUSTOM_FIELD_5 = 'CUSTOM_FIELD_5',
  CUSTOM_FIELD_5_LEVEL_1 = 'CUSTOM_FIELD_5_LEVEL_1',
  CUSTOM_FIELD_5_LEVEL_2 = 'CUSTOM_FIELD_5_LEVEL_2',
  CUSTOM_FIELD_5_LEVEL_3 = 'CUSTOM_FIELD_5_LEVEL_3',
  CUSTOM_FIELD_5_LEVEL_4 = 'CUSTOM_FIELD_5_LEVEL_4',
  CUSTOM_FIELD_5_LEVEL_5 = 'CUSTOM_FIELD_5_LEVEL_5',
  CUSTOM_FIELD_5_LEVEL_6 = 'CUSTOM_FIELD_5_LEVEL_6',
  CUSTOM_FIELD_5_LEVEL_7 = 'CUSTOM_FIELD_5_LEVEL_7',
  CUSTOM_FIELD_5_LEVEL_8 = 'CUSTOM_FIELD_5_LEVEL_8',
  CUSTOM_FIELD_5_LEVEL_9 = 'CUSTOM_FIELD_5_LEVEL_9',
  CUSTOM_FIELD_5_LEVEL_10 = 'CUSTOM_FIELD_5_LEVEL_10',
  CUSTOM_FIELD_6 = 'CUSTOM_FIELD_6',
  CUSTOM_FIELD_6_LEVEL_1 = 'CUSTOM_FIELD_6_LEVEL_1',
  CUSTOM_FIELD_6_LEVEL_2 = 'CUSTOM_FIELD_6_LEVEL_2',
  CUSTOM_FIELD_6_LEVEL_3 = 'CUSTOM_FIELD_6_LEVEL_3',
  CUSTOM_FIELD_6_LEVEL_4 = 'CUSTOM_FIELD_6_LEVEL_4',
  CUSTOM_FIELD_6_LEVEL_5 = 'CUSTOM_FIELD_6_LEVEL_5',
  CUSTOM_FIELD_6_LEVEL_6 = 'CUSTOM_FIELD_6_LEVEL_6',
  CUSTOM_FIELD_6_LEVEL_7 = 'CUSTOM_FIELD_6_LEVEL_7',
  CUSTOM_FIELD_6_LEVEL_8 = 'CUSTOM_FIELD_6_LEVEL_8',
  CUSTOM_FIELD_6_LEVEL_9 = 'CUSTOM_FIELD_6_LEVEL_9',
  CUSTOM_FIELD_6_LEVEL_10 = 'CUSTOM_FIELD_6_LEVEL_10',
  CUSTOM_FIELD_7 = 'CUSTOM_FIELD_7',
  CUSTOM_FIELD_7_LEVEL_1 = 'CUSTOM_FIELD_7_LEVEL_1',
  CUSTOM_FIELD_7_LEVEL_2 = 'CUSTOM_FIELD_7_LEVEL_2',
  CUSTOM_FIELD_7_LEVEL_3 = 'CUSTOM_FIELD_7_LEVEL_3',
  CUSTOM_FIELD_7_LEVEL_4 = 'CUSTOM_FIELD_7_LEVEL_4',
  CUSTOM_FIELD_7_LEVEL_5 = 'CUSTOM_FIELD_7_LEVEL_5',
  CUSTOM_FIELD_7_LEVEL_6 = 'CUSTOM_FIELD_7_LEVEL_6',
  CUSTOM_FIELD_7_LEVEL_7 = 'CUSTOM_FIELD_7_LEVEL_7',
  CUSTOM_FIELD_7_LEVEL_8 = 'CUSTOM_FIELD_7_LEVEL_8',
  CUSTOM_FIELD_7_LEVEL_9 = 'CUSTOM_FIELD_7_LEVEL_9',
  CUSTOM_FIELD_7_LEVEL_10 = 'CUSTOM_FIELD_7_LEVEL_10',
  CUSTOM_FIELD_8 = 'CUSTOM_FIELD_8',
  CUSTOM_FIELD_8_LEVEL_1 = 'CUSTOM_FIELD_8_LEVEL_1',
  CUSTOM_FIELD_8_LEVEL_2 = 'CUSTOM_FIELD_8_LEVEL_2',
  CUSTOM_FIELD_8_LEVEL_3 = 'CUSTOM_FIELD_8_LEVEL_3',
  CUSTOM_FIELD_8_LEVEL_4 = 'CUSTOM_FIELD_8_LEVEL_4',
  CUSTOM_FIELD_8_LEVEL_5 = 'CUSTOM_FIELD_8_LEVEL_5',
  CUSTOM_FIELD_8_LEVEL_6 = 'CUSTOM_FIELD_8_LEVEL_6',
  CUSTOM_FIELD_8_LEVEL_7 = 'CUSTOM_FIELD_8_LEVEL_7',
  CUSTOM_FIELD_8_LEVEL_8 = 'CUSTOM_FIELD_8_LEVEL_8',
  CUSTOM_FIELD_8_LEVEL_9 = 'CUSTOM_FIELD_8_LEVEL_9',
  CUSTOM_FIELD_8_LEVEL_10 = 'CUSTOM_FIELD_8_LEVEL_10',
  CUSTOM_FIELD_9 = 'CUSTOM_FIELD_9',
  CUSTOM_FIELD_9_LEVEL_1 = 'CUSTOM_FIELD_9_LEVEL_1',
  CUSTOM_FIELD_9_LEVEL_2 = 'CUSTOM_FIELD_9_LEVEL_2',
  CUSTOM_FIELD_9_LEVEL_3 = 'CUSTOM_FIELD_9_LEVEL_3',
  CUSTOM_FIELD_9_LEVEL_4 = 'CUSTOM_FIELD_9_LEVEL_4',
  CUSTOM_FIELD_9_LEVEL_5 = 'CUSTOM_FIELD_9_LEVEL_5',
  CUSTOM_FIELD_9_LEVEL_6 = 'CUSTOM_FIELD_9_LEVEL_6',
  CUSTOM_FIELD_9_LEVEL_7 = 'CUSTOM_FIELD_9_LEVEL_7',
  CUSTOM_FIELD_9_LEVEL_8 = 'CUSTOM_FIELD_9_LEVEL_8',
  CUSTOM_FIELD_9_LEVEL_9 = 'CUSTOM_FIELD_9_LEVEL_9',
  CUSTOM_FIELD_9_LEVEL_10 = 'CUSTOM_FIELD_9_LEVEL_10',
  CUSTOM_FIELD_10 = 'CUSTOM_FIELD_10',
  CUSTOM_FIELD_10_LEVEL_1 = 'CUSTOM_FIELD_10_LEVEL_1',
  CUSTOM_FIELD_10_LEVEL_2 = 'CUSTOM_FIELD_10_LEVEL_2',
  CUSTOM_FIELD_10_LEVEL_3 = 'CUSTOM_FIELD_10_LEVEL_3',
  CUSTOM_FIELD_10_LEVEL_4 = 'CUSTOM_FIELD_10_LEVEL_4',
  CUSTOM_FIELD_10_LEVEL_5 = 'CUSTOM_FIELD_10_LEVEL_5',
  CUSTOM_FIELD_10_LEVEL_6 = 'CUSTOM_FIELD_10_LEVEL_6',
  CUSTOM_FIELD_10_LEVEL_7 = 'CUSTOM_FIELD_10_LEVEL_7',
  CUSTOM_FIELD_10_LEVEL_8 = 'CUSTOM_FIELD_10_LEVEL_8',
  CUSTOM_FIELD_10_LEVEL_9 = 'CUSTOM_FIELD_10_LEVEL_9',
  CUSTOM_FIELD_10_LEVEL_10 = 'CUSTOM_FIELD_10_LEVEL_10',
  SPAN_OF_CONTROL = 'SPAN_OF_CONTROL',
  DEPTH_OF_CONTROL = 'DEPTH_OF_CONTROL',
  DEFINED_MANAGER = 'DEFINED_MANAGER',
  BASE_BOARD_COMPENSATION = 'BASE_BOARD_COMPENSATION',
  VARIABLE_BOARD_COMPENSATION = 'VARIABLE_BOARD_COMPENSATION',
  DEPARTMENT = 'DEPARTMENT',
  EFFECTIVE_LEAVER_DATE_NORMALIZED_YEAR_MONTH = 'EFFECTIVE_LEAVER_DATE_NORMALIZED_YEAR_MONTH',
  FTE_VALUE = 'FTE_VALUE',
  move_from = 'move_from', //Not really an employee field but the backend returns it with EMPLOYEE datatype
  move_to = 'move_to', //Not really an employee field but the backend returns it with EMPLOYEE datatype
  TENURE_IN_JOBGRADE_LEVEL_1 = 'TENURE_IN_JOBGRADE_LEVEL_1',
  TENURE_IN_JOBGRADE_LEVEL_2 = 'TENURE_IN_JOBGRADE_LEVEL_2',
  TENURE_IN_PREVIOUS_JOBGRADE_LEVEL_1 = 'TENURE_IN_PREVIOUS_JOBGRADE_LEVEL_1',
  TENURE_AS_MANAGER = 'TENURE_AS_MANAGER',
  TENURE_IN_ORG_LEVEL_1 = 'TENURE_IN_ORG_LEVEL_1',
  TENURE_IN_ORG_LEVEL_2 = 'TENURE_IN_ORG_LEVEL_2',
  STATUTORY_ELIGIBILITY_FOR_PARENTAL_LEAVE = 'STATUTORY_ELIGIBILITY_FOR_PARENTAL_LEAVE',
  DISABILITY_SEVERITY = 'DISABILITY_SEVERITY',
  MANAGEMENT_POOL = 'MANAGEMENT_POOL',
  YOUNGEST_CHILD_BIRTHDATE = 'YOUNGEST_CHILD_BIRTHDATE',
  LATEST_PARENTAL_LEAVE_STARTDATE = 'LATEST_PARENTAL_LEAVE_STARTDATE',
  CUSTOM_START_DATE = 'CUSTOM_START_DATE',
  CUSTOM_DAYS_IN_COMPANY = 'CUSTOM_DAYS_IN_COMPANY',
  CUSTOM_TENURE_GROUP = 'CUSTOM_TENURE_GROUP',
  START_CALENDAR_YEAR = 'START_CALENDAR_YEAR',
  START_FINANCIAL_YEAR = 'START_FINANCIAL_YEAR',
}

export enum EvaluationDataFields {
  EVALUATION_ID = 'EVALUATION_ID',
  EFFECTIVE_DATE = 'EFFECTIVE_DATE',
  EMPLOYEE_ID = 'EMPLOYEE_ID',
  EVALUATION_SCORE = 'EVALUATION_SCORE',
  EVALUATION_CYCLE_TYPE = 'EVALUATION_CYCLE_TYPE',
  EVALUATION_CYCLE_NAME = 'EVALUATION_CYCLE_NAME',
  EVALUATION_CYCLE_VIZ_TYPE = 'EVALUATION_CYCLE_VIZ_TYPE',
  EVALUATION_AS_OF = 'EVALUATION_AS_OF',
  VERSION_ID = 'VERSION_ID',
}

export enum ApplicationDataFields {
  APPLICATION_ID = 'APPLICATION_ID',
  STANDARDIZED_CURRENT_STAGE = 'STANDARDIZED_CURRENT_STAGE',
  STANDARDIZED_CURRENT_STAGE_OUTCOME = 'STANDARDIZED_CURRENT_STAGE_OUTCOME',
  RESOLVED_AT = 'RESOLVED_AT',
  REJECTED_AT = 'REJECTED_AT',
  START_MONTH = 'START_MONTH',
  START_YEAR = 'START_YEAR',
  APPLIED_AT = 'APPLIED_AT',
  CANDIDATE_ID = 'CANDIDATE_ID',
  CANDIDATE_EMAIL_ADDRESS = 'CANDIDATE_EMAIL_ADDRESS',
  CANDIDATE_FIRST_NAME = 'CANDIDATE_FIRST_NAME',
  CANDIDATE_LAST_NAME = 'CANDIDATE_LAST_NAME',
  SOURCE = 'SOURCE',
  SOURCE_LEVEL_1 = 'SOURCE_LEVEL_1',
  SOURCE_LEVEL_2 = 'SOURCE_LEVEL_2',
  SOURCE_LEVEL_3 = 'SOURCE_LEVEL_3',
  SOURCE_LEVEL_4 = 'SOURCE_LEVEL_4',
  SOURCE_LEVEL_5 = 'SOURCE_LEVEL_5',
  SOURCE_LEVEL_6 = 'SOURCE_LEVEL_6',
  SOURCE_LEVEL_7 = 'SOURCE_LEVEL_7',
  SOURCE_LEVEL_8 = 'SOURCE_LEVEL_8',
  SOURCE_LEVEL_9 = 'SOURCE_LEVEL_9',
  SOURCE_LEVEL_10 = 'SOURCE_LEVEL_10',
  SOURCE_TYPE = 'SOURCE_TYPE',
  CURRENT_STAGE = 'CURRENT_STAGE',
  APPLICATION_AGE = 'APPLICATION_AGE',
  CURRENT_JOB_TITLE = 'CURRENT_JOB_TITLE',
  REJECTION_REASON = 'REJECTION_REASON',
  REJECTION_REASON_LEVEL_1 = 'REJECTION_REASON_LEVEL_1',
  REJECTION_REASON_LEVEL_2 = 'REJECTION_REASON_LEVEL_2',
  APPLICATION_CURRENT_STAGE = 'APPLICATION_CURRENT_STAGE',
  APPLICATION_CURRENT_STAGE_LEVEL_1 = 'APPLICATION_CURRENT_STAGE_LEVEL_1',
  APPLICATION_CURRENT_STAGE_LEVEL_2 = 'APPLICATION_CURRENT_STAGE_LEVEL_2',
  APPLICATION_CURRENT_STAGE_LEVEL_3 = 'APPLICATION_CURRENT_STAGE_LEVEL_3',
  APPLICATION_CURRENT_STAGE_OUTCOME = 'APPLICATION_CURRENT_STAGE_OUTCOME',
  APPLICATION_CURRENT_STAGE_LEVEL1_OUTCOME = 'APPLICATION_CURRENT_STAGE_LEVEL1_OUTCOME',
  APPLICATION_CURRENT_STAGE_LEVEL2_OUTCOME = 'APPLICATION_CURRENT_STAGE_LEVEL2_OUTCOME',
  APPLICATION_CURRENT_STAGE_LEVEL3_OUTCOME = 'APPLICATION_CURRENT_STAGE_LEVEL3_OUTCOME',
  VERSION_ID = 'VERSION_ID',
  JOB_ID = 'JOB_ID',
  STATUS = 'STATUS',
  OFFICE = 'OFFICE',
  LOCATION = 'LOCATION',
  ORGANIZATION = 'ORGANIZATION',
  JOB_NAME = 'JOB_NAME',
  SOURCE_ID = 'SOURCE_ID',
  SOURCE_NAME = 'SOURCE_NAME',
  EFFECTIVE_DATE = 'EFFECTIVE_DATE',
  DEGREE_TYPE = 'DEGREE_TYPE',
  DEGREE_MAJOR = 'DEGREE_MAJOR',
  CUSTOM_FIELD_1 = 'CUSTOM_FIELD_1',
  CUSTOM_FILTER_1 = 'CUSTOM_FILTER_1',
  CUSTOM_FILTER_2 = 'CUSTOM_FILTER_2',
  CUSTOM_FILTER_3 = 'CUSTOM_FILTER_3',
  CUSTOM_FILTER_4 = 'CUSTOM_FILTER_4',
  CUSTOM_FILTER_5 = 'CUSTOM_FILTER_5',
}

export enum JobDataFields {
  EFFECTIVE_DATE = 'EFFECTIVE_DATE',
  VERSION_ID = 'VERSION_ID',
  JOB_AGE = 'JOB_AGE',
  JOB_ID = 'JOB_ID',
  JOB_NAME = 'JOB_NAME',
  JOB_NAME_LEVEL_1 = 'JOB_NAME_LEVEL_1',
  JOB_NAME_LEVEL_2 = 'JOB_NAME_LEVEL_2',
  JOB_NAME_LEVEL_3 = 'JOB_NAME_LEVEL_3',
  JOB_NAME_LEVEL_4 = 'JOB_NAME_LEVEL_4',
  JOB_NAME_LEVEL_5 = 'JOB_NAME_LEVEL_5',
  JOB_NAME_LEVEL_6 = 'JOB_NAME_LEVEL_6',
  JOB_NAME_LEVEL_7 = 'JOB_NAME_LEVEL_7',
  JOB_NAME_LEVEL_8 = 'JOB_NAME_LEVEL_8',
  JOB_NAME_LEVEL_9 = 'JOB_NAME_LEVEL_9',
  JOB_NAME_LEVEL_10 = 'JOB_NAME_LEVEL_10',
  JOB_GRADE = 'JOB_GRADE',
  JOB_GRADE_LEVEL_1 = 'JOB_GRADE_LEVEL_1',
  JOB_GRADE_LEVEL_2 = 'JOB_GRADE_LEVEL_2',
  JOB_GRADE_LEVEL_3 = 'JOB_GRADE_LEVEL_3',
  JOB_GRADE_LEVEL_4 = 'JOB_GRADE_LEVEL_4',
  JOB_GRADE_LEVEL_5 = 'JOB_GRADE_LEVEL_5',
  JOB_GRADE_LEVEL_6 = 'JOB_GRADE_LEVEL_6',
  JOB_GRADE_LEVEL_7 = 'JOB_GRADE_LEVEL_7',
  JOB_GRADE_LEVEL_8 = 'JOB_GRADE_LEVEL_8',
  JOB_GRADE_LEVEL_9 = 'JOB_GRADE_LEVEL_9',
  JOB_GRADE_LEVEL_10 = 'JOB_GRADE_LEVEL_10',
  OPENED_AT = 'OPENED_AT',
  CLOSED_AT = 'CLOSED_AT',
  OFFICE = 'OFFICE',
  OFFICE_LEVEL_1 = 'OFFICE_LEVEL_1',
  OFFICE_LEVEL_2 = 'OFFICE_LEVEL_2',
  OFFICE_LEVEL_3 = 'OFFICE_LEVEL_3',
  OFFICE_LEVEL_4 = 'OFFICE_LEVEL_4',
  OFFICE_LEVEL_5 = 'OFFICE_LEVEL_5',
  OFFICE_LEVEL_6 = 'OFFICE_LEVEL_6',
  OFFICE_LEVEL_7 = 'OFFICE_LEVEL_7',
  OFFICE_LEVEL_8 = 'OFFICE_LEVEL_8',
  OFFICE_LEVEL_9 = 'OFFICE_LEVEL_9',
  OFFICE_LEVEL_10 = 'OFFICE_LEVEL_10',
  ORGANIZATION = 'ORGANIZATION',
  ORGANIZATION_LEVEL_1 = 'ORGANIZATION_LEVEL_1',
  ORGANIZATION_LEVEL_2 = 'ORGANIZATION_LEVEL_2',
  ORGANIZATION_LEVEL_3 = 'ORGANIZATION_LEVEL_3',
  ORGANIZATION_LEVEL_4 = 'ORGANIZATION_LEVEL_4',
  ORGANIZATION_LEVEL_5 = 'ORGANIZATION_LEVEL_5',
  ORGANIZATION_LEVEL_6 = 'ORGANIZATION_LEVEL_6',
  ORGANIZATION_LEVEL_7 = 'ORGANIZATION_LEVEL_7',
  ORGANIZATION_LEVEL_8 = 'ORGANIZATION_LEVEL_8',
  ORGANIZATION_LEVEL_9 = 'ORGANIZATION_LEVEL_9',
  ORGANIZATION_LEVEL_10 = 'ORGANIZATION_LEVEL_10',
  LOCATION = 'LOCATION',
  LOCATION_LEVEL_1 = 'LOCATION_LEVEL_1',
  LOCATION_LEVEL_2 = 'LOCATION_LEVEL_2',
  LOCATION_LEVEL_3 = 'LOCATION_LEVEL_3',
  LOCATION_LEVEL_4 = 'LOCATION_LEVEL_4',
  LOCATION_LEVEL_5 = 'LOCATION_LEVEL_5',
  LOCATION_LEVEL_6 = 'LOCATION_LEVEL_6',
  LOCATION_LEVEL_7 = 'LOCATION_LEVEL_7',
  LOCATION_LEVEL_8 = 'LOCATION_LEVEL_8',
  LOCATION_LEVEL_9 = 'LOCATION_LEVEL_9',
  LOCATION_LEVEL_10 = 'LOCATION_LEVEL_10',
  FUNCTION = 'FUNCTION',
  FUNCTION_LEVEL_1 = 'FUNCTION_LEVEL_1',
  FUNCTION_LEVEL_2 = 'FUNCTION_LEVEL_2',
  FUNCTION_LEVEL_3 = 'FUNCTION_LEVEL_3',
  FUNCTION_LEVEL_4 = 'FUNCTION_LEVEL_4',
  FUNCTION_LEVEL_5 = 'FUNCTION_LEVEL_5',
  FUNCTION_LEVEL_6 = 'FUNCTION_LEVEL_6',
  FUNCTION_LEVEL_7 = 'FUNCTION_LEVEL_7',
  FUNCTION_LEVEL_8 = 'FUNCTION_LEVEL_8',
  FUNCTION_LEVEL_9 = 'FUNCTION_LEVEL_9',
  FUNCTION_LEVEL_10 = 'FUNCTION_LEVEL_10',
  RECRUITMENT_CATEGORY = 'RECRUITMENT_CATEGORY',
  STATUS = 'STATUS',
  RECRUITMENT_CATEGORY_HIERARCHICAL = 'RECRUITMENT_CATEGORY_HIERARCHICAL',
  CUSTOM_FIELD_1 = 'CUSTOM_FIELD_1',
  CUSTOM_FIELD_2 = 'CUSTOM_FIELD_2',
}

export enum OfferDataFields {
  EFFECTIVE_DATE = 'EFFECTIVE_DATE',
  VERSION_ID = 'VERSION_ID',
  STATUS = 'STATUS',
  STARTS_AT = 'STARTS_AT',
  APPLICATION_ID = 'APPLICATION_ID',
  OFFER_ID = 'OFFER_ID',
  JOB_ID = 'JOB_ID',
  CREATED_AT = 'CREATED_AT',
  RESOLVED_AT = 'RESOLVED_AT',
}

export enum FinancialDataFields {
  FINANCIALMETRICSID = 'FINANCIALMETRICSID',
  VERSION_ID = 'VERSION_ID',
  REVENUE = 'REVENUE',
  GROSS_PROFIT = 'GROSS_PROFIT',
  OPERATING_PROFIT = 'OPERATING_PROFIT',
  NET_INCOME = 'NET_INCOME',
  SELLING_GENERAL_AND_ADMINISTRATION_EXPENSES = 'SELLING_GENERAL_AND_ADMINISTRATION_EXPENSES',
  TRAINING_COST = 'TRAINING_COST',
  ORGANIZATION_LEVEL_1 = 'ORGANIZATION_LEVEL_1',
  FUNCTION_LEVEL_1 = 'FUNCTION_LEVEL_1',
  GRANULARITY = 'GRANULARITY',
  COST_CENTER = 'COST_CENTER',
  LABOUR_COST = 'LABOUR_COST',
}

export const JoinersViewDataFields = { ...EmployeeDataFields };
export type JoinersViewDataFields = typeof JoinersViewDataFields[keyof typeof JoinersViewDataFields];

export type DataFields =
  | EmployeeDataFields
  | JoinersViewDataFields
  | EvaluationDataFields
  | ApplicationDataFields
  | JobDataFields
  | OfferDataFields
  | FinancialDataFields;

export interface DataFieldWithDataType {
  dataType: DataTypes;
  dataField: DataFields;
}

export enum Operations {
  LESS_THAN = 'LESS_THAN',
  LESS_THAN_OR_EQUAL_TO = 'LESS_THAN_OR_EQUAL_TO',
  GREATER_THAN = 'GREATER_THAN',
  GREATER_THAN_OR_EQUAL_TO = 'GREATER_THAN_OR_EQUAL_TO',
  COUNT = 'COUNT',
  EQUAL = 'EQUAL',
  NOT_EQUAL = 'NOT_EQUAL',
  AVG = 'AVG',
  COUNT_DISTINCT = 'COUNT_DISTINCT',
  MAX = 'MAX',
  MIN = 'MIN',
  MEDIAN = 'MEDIAN',
  SUM = 'SUM',
  LIKE = 'LIKE',
  IN = 'IN',
  NOT_IN = 'NOT_IN',
}

export enum JoinTypes {
  INNER = 'INNER',
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export enum Versioning {
  NONE = 'NONE',
  DAILY = 'DAILY',
  MONTHLY = 'MONTHLY',
}

export interface DataTypesAttributes {
  versioning: Versioning;
  /**
   * @deprecated This is a column that we join on when filters or measures are specified for different tables than the base table and those tables are not joined explicitly.
   * Please don't use this! Instead, if you find yourself repeatingly writing explicit joins between two datatypes, then write a helper function to add the join.
   * However, in the future it might make sense to indicate, if a table has one or multiple employee-id columns.
   */
  defaultIdColumn: DataFields;
}

export type SingleValueTypes = string | number | null;
export type HierarchicalValueTypes = SingleValueTypes[];
export type NonHierarchicalValueTypes = SingleValueTypes;
export type ValueTypes = NonHierarchicalValueTypes | HierarchicalValueTypes;

export interface ApiMasterDataQueryFilterItem {
  operation: Operations;
  property: string;
  values: ValueTypes[];
  dataType: DataTypes;
  dontCombine?: boolean;
}

export interface ApiMasterDataAdvancedQueryDimension {
  dataType: DataTypes;
  property: DataFields;
}

export interface ApiMasterDataQueryMeasure {
  operation: Operations;
  property: string;
  name?: string;
  dataType: DataTypes;
}

export enum ApiMasterDataQuerySortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface ApiMasterDataQueryOrderBy {
  dimension: ApiMasterDataAdvancedQueryDimension;
  sortOrder: ApiMasterDataQuerySortOrder;
}

export interface ApiMasterDataAdvancedQuery {
  dataType: DataTypes;
  dimensions?: ApiMasterDataAdvancedQueryDimension[];
  joins?: ApiMasterDataAdvancedQueryJoins[];
  filterItems?: Filter[];
  measures?: ApiMasterDataQueryMeasure[];
  orderBy?: ApiMasterDataQueryOrderBy[];
  limit?: number;
  offset?: number;
  disableNestLoop?: boolean;
}

interface ApiMasterDataAdvancedQueryJoinConditions {
  leftDataType: DataTypes;
  leftProperty: DataFields;
  rightDataType: DataTypes;
  rightProperty: DataFields;
  operation: Operations;
}

export interface ApiMasterDataAdvancedQueryJoins {
  joinType: JoinTypes;
  joinDataType: DataTypes;
  joinConditions?: ApiMasterDataAdvancedQueryJoinConditions[];
}

export interface SegmentationByNonHierarchicalField {
  type: 'non-hierarchical';
  dataField: DataFieldWithDataType;
  filters?: DataValue[];
}

export interface SegmentationByHierarchicalField {
  type: 'hierarchical';
  dataField: DataFieldWithDataType;
  filters?: DataValue[][];
}

export type Segmentation = SegmentationByNonHierarchicalField | SegmentationByHierarchicalField;

export interface CalendarYearSingleValueByMonthsTimeSelection {
  type: TimeSelectionType.CalendarYearSingleValueByMonths;
  input: CalendarYearSingleValueByMonthsSegment;
}

export interface CalendarYearSingleValueByYearsTimeSelection {
  type: TimeSelectionType.CalendarYearSingleValueByYears;
  input: CalendarYearSingleValueByYearsSegment;
}

export interface FinancialYearSingleValueByYearsTimeSelection {
  type: TimeSelectionType.FinancialYearSingleValueByYears;
  input: FinancialYearSingleValueByYearsSegment;
}

export interface FinancialYearSingleValueByQuartersTimeSelection {
  type: TimeSelectionType.FinancialYearSingleValueByQuarters;
  input: FinancialYearSingleValueByQuartersSegment;
}

export enum TimeSelectionType {
  CalendarYearSingleValueByMonths,
  CalendarYearSingleValueByYears,
  FinancialYearSingleValueByQuarters,
  FinancialYearSingleValueByYears,
  CalendarYearMonthly,
  CalendarYearQuarterly,
  CalendarYearYearly,
  FinancialYearQuarterly,
  FinancialYearYearly,
}

export type OverTimeTimeSelection =
  | { type: TimeSelectionType.CalendarYearMonthly; input: CalendarYearMonthlyTimeSelectionInput }
  | { type: TimeSelectionType.CalendarYearQuarterly; input: CalendarYearQuarterlyTimeSelectionInput }
  | { type: TimeSelectionType.CalendarYearYearly; input: CalendarYearYearlyTimeSelectionInput }
  | { type: TimeSelectionType.FinancialYearQuarterly; input: FinancialYearQuarterlyTimeSelectionInput }
  | { type: TimeSelectionType.FinancialYearYearly; input: FinancialYearYearlyTimeSelectionInput };

export type SingleValueTimeSelection =
  | CalendarYearSingleValueByMonthsTimeSelection
  | CalendarYearSingleValueByYearsTimeSelection
  | FinancialYearSingleValueByYearsTimeSelection
  | FinancialYearSingleValueByQuartersTimeSelection;

export type TimeSelection = OverTimeTimeSelection | SingleValueTimeSelection;

export interface Monoid<T> {
  combine: (r1: T, r2: T) => T;
  empty: T;
}

export type NonEmptyArray<T> = [T | undefined, ...T[]];
