import { DomainPreferences } from '../../api/types';
import { RegularMetricId } from '../../api/types-graphql';
import { DashboardConfig } from '../../common/components/dashboards/types';
import { Months } from '../../common/components/timeslider/types';
import { Languages } from '../../constants';
import { DataTypes, EmployeeDataFields, TimeSelectionType } from '../../types';
import { fiscalYearStart, monthEnd, now as nowUtils } from '../../utils-date';
import { lastMonthEndOfMonth } from './utils';

const thisMonth = monthEnd(nowUtils());
const now = thisMonth;
const startOfYear = (domainPreferences: DomainPreferences) => {
  const finMonth = domainPreferences.finYearStartMonth ?? Months.January;
  return fiscalYearStart(thisMonth, finMonth);
};

export const JTBDashboardChartConfig: (domainPreferences: DomainPreferences) => DashboardConfig = (
  domainPreferences: DomainPreferences
) => ({
  id: 'jtb_custom_db_1',
  title: {
    [Languages.EN]: 'KPI and Related Metrics',
    [Languages.JA]: 'KPIと関連指標',
  },
  enabled: true,
  pages: [
    {
      pageId: '1',
      dashboardId: 'jtb_custom_db_1',
      pageNumber: 1,
      enabled: true,
      title: {
        [Languages.EN]: 'Page 1',
        [Languages.JA]: 'ページ１',
      },
      pageOptions: {
        displayOptions: {
          enabled: false,
        },
        timeSlider: {
          enabled: false,
        },
        filterTray: {
          enabled: false,
        },
        dataView: {
          enabled: false,
        },
        segmentationLevel1: {
          enabled: false,
        },
        segmentationLevel2: {
          enabled: false,
        },
        benchmark: {
          enabled: false,
        },
        forecast: {
          enabled: false,
        },
      },
      gridCols: 20,
      components: [
        {
          chartId: '1',
          title: {
            [Languages.EN]: 'Individuals',
            [Languages.JA]: '従業員数',
          },
          subtitle: {
            [Languages.EN]: '(Excluding seconded employees)',
            [Languages.JA]: '(出向者を除く)',
          },
          chartTypeConfig: { chartType: 'kpi' },
          gridColSpan: 5,
          sections: [
            {
              key: 'individuals-excluding-seconded-employees',
              queries: [
                {
                  metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0001_1IndividualsCount }],
                  timeSelection: {
                    type: TimeSelectionType.CalendarYearSingleValueByMonths,
                    input: { start: now, end: now },
                  },
                  filters: `EMPLOYEE.ORGANIZATION_LEVEL_1 = 'エリアソリューション' OR EMPLOYEE.ORGANIZATION_LEVEL_1 = 'グローバル' OR EMPLOYEE.ORGANIZATION_LEVEL_1 = 'ツーリズム' OR EMPLOYEE.ORGANIZATION_LEVEL_1 = 'ビジネスソリューション' OR EMPLOYEE.ORGANIZATION_LEVEL_1 = '事業基盤機能' OR EMPLOYEE.ORGANIZATION_LEVEL_1 IS NULL OR (EMPLOYEE.ORGANIZATION_LEVEL_1 = '休職者・出向者など' AND EMPLOYEE.ORGANIZATION_LEVEL_2 = 'その他') OR (EMPLOYEE.ORGANIZATION_LEVEL_1 = '休職者・出向者など' AND EMPLOYEE.ORGANIZATION_LEVEL_2 = '休務・休職者')`,
                },
              ],
            },
            {
              key: 'individuals-excluding-seconded-employees-segmented',
              queries: [
                {
                  metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0001_1IndividualsCount }],
                  timeSelection: {
                    type: TimeSelectionType.CalendarYearSingleValueByMonths,
                    input: { start: now, end: now },
                  },
                  segmentations: [
                    {
                      type: 'hierarchical',
                      dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.ORGANIZATION },
                      filters: [
                        ['エリアソリューション'],
                        ['グローバル'],
                        ['ツーリズム'],
                        ['ビジネスソリューション'],
                        ['事業基盤機能'],
                        ['休職者・出向者など'],
                      ],
                    },
                  ],
                  filters: `EMPLOYEE.ORGANIZATION_LEVEL_1 = 'エリアソリューション' OR EMPLOYEE.ORGANIZATION_LEVEL_1 = 'グローバル' OR EMPLOYEE.ORGANIZATION_LEVEL_1 = 'ツーリズム' OR EMPLOYEE.ORGANIZATION_LEVEL_1 = 'ビジネスソリューション' OR EMPLOYEE.ORGANIZATION_LEVEL_1 = '事業基盤機能' OR EMPLOYEE.ORGANIZATION_LEVEL_1 IS NULL OR (EMPLOYEE.ORGANIZATION_LEVEL_1 = '休職者・出向者など' AND EMPLOYEE.ORGANIZATION_LEVEL_2 = 'その他') OR (EMPLOYEE.ORGANIZATION_LEVEL_1 = '休職者・出向者など' AND EMPLOYEE.ORGANIZATION_LEVEL_2 = '休務・休職者')`,
                },
              ],
            },
          ],
        },
        {
          chartId: '2',
          title: {
            [Languages.EN]: 'Individuals',
            [Languages.JA]: '従業員数',
          },
          subtitle: {
            [Languages.EN]: '(Seconded employees)',
            [Languages.JA]: '(出向者)',
          },
          chartTypeConfig: { chartType: 'kpi' },
          gridColSpan: 5,
          sections: [
            {
              key: 'individuals-seconded-employees-segmented',
              queries: [
                {
                  metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0001_1IndividualsCount }],
                  timeSelection: {
                    type: TimeSelectionType.CalendarYearSingleValueByMonths,
                    input: { start: now, end: now },
                  },
                  filters: `EMPLOYEE.ORGANIZATION_LEVEL_1 = '休職者・出向者など' AND EMPLOYEE.ORGANIZATION_LEVEL_2 = '出向者'`,
                },
              ],
            },
          ],
        },
        {
          chartId: '3',
          title: {
            [Languages.EN]: '# of Joiners',
            [Languages.JA]: '入社者',
          },
          subtitle: {
            [Languages.EN]: '(Career + New Graduates)',
            [Languages.JA]: '(キャリア+新卒)',
          },
          chartTypeConfig: { chartType: 'kpi' },
          gridColSpan: 5,
          sections: [
            {
              key: 'joiners-current-month',
              title: {
                [Languages.EN]: '(Most recent month)',
                [Languages.JA]: '(最新の月間値)',
              },
              queries: [
                {
                  metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0006_1JoinersJoiners }],
                  timeSelection: {
                    type: TimeSelectionType.CalendarYearSingleValueByMonths,
                    input: { start: now, end: now },
                  },
                  filters: `EMPLOYEE.CUSTOM_FIELD_1_LEVEL_1 = 'キャリア・既卒' OR EMPLOYEE.CUSTOM_FIELD_1_LEVEL_1 = '新卒'`,
                },
              ],
            },
            {
              key: 'joiners-ytd',
              title: {
                [Languages.EN]: '(FYTD)',
                [Languages.JA]: '(期初からの累計)',
              },
              queries: [
                {
                  metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0006_1JoinersJoiners }],
                  timeSelection: {
                    type: TimeSelectionType.CalendarYearSingleValueByMonths,
                    input: { start: startOfYear(domainPreferences), end: now },
                  },
                  filters: `EMPLOYEE.CUSTOM_FIELD_1_LEVEL_1 = 'キャリア・既卒' OR EMPLOYEE.CUSTOM_FIELD_1_LEVEL_1 = '新卒'`,
                },
              ],
            },
          ],
        },
        {
          chartId: '4',
          title: {
            [Languages.EN]: 'Voluntary Attrition',
            [Languages.JA]: '自己都合離職者',
          },
          subtitle: {
            [Languages.EN]: '(FYTD)',
            [Languages.JA]: '(期初からの累計)',
          },
          chartTypeConfig: { chartType: 'kpi' },
          gridColSpan: 5,
          align: 'row',
          sections: [
            {
              key: 'voluntary-attrition-ytd',
              queries: [
                {
                  metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0237_4VoluntaryAttritionLeavers }],
                  timeSelection: {
                    type: TimeSelectionType.CalendarYearSingleValueByMonths,
                    input: { start: startOfYear(domainPreferences), end: now },
                  },
                },
              ],
            },
          ],
        },
        {
          chartId: '5',
          title: {
            [Languages.EN]: 'Female Headcount',
            [Languages.JA]: '女性従業員',
          },
          subtitle: {
            [Languages.EN]: '(Most recent month)',
            [Languages.JA]: '(最新の月間値)',
          },
          chartTypeConfig: { chartType: 'kpi' },
          gridColSpan: 4,
          align: 'row',
          sections: [
            {
              key: 'female-headcount',
              queries: [
                {
                  metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0020_2FhcPercHc }],
                  timeSelection: {
                    type: TimeSelectionType.CalendarYearSingleValueByMonths,
                    input: { start: now, end: now },
                  },
                },
              ],
            },
          ],
        },
        {
          chartId: '6',
          title: {
            [Languages.EN]: 'Female Managers (Defined)',
            [Languages.JA]: '女性管理職',
          },
          subtitle: {
            [Languages.EN]: '(Most recent month)',
            [Languages.JA]: '(最新の月間値)',
          },
          chartTypeConfig: { chartType: 'kpi' },
          gridColSpan: 4,
          align: 'row',
          sections: [
            {
              key: 'female-managers-current-month',
              queries: [
                {
                  metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0016_3FMngDefPercMng }],
                  timeSelection: {
                    type: TimeSelectionType.CalendarYearSingleValueByMonths,
                    input: { start: now, end: now },
                  },
                },
              ],
            },
          ],
        },
        {
          chartId: '7',
          title: {
            [Languages.EN]: 'Avg Work Hours per Person',
            [Languages.JA]: '総労働時間',
          },
          subtitle: {
            [Languages.EN]: '(Most recent month)',
            [Languages.JA]: '(最新の月間値)',
          },
          chartTypeConfig: { chartType: 'kpi' },
          gridColSpan: 4,
          sections: [
            {
              key: 'total-work-hours',
              queries: [
                {
                  metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0213_2AvgWorkHoursPerPersonAvgWithWh }],
                  timeSelection: {
                    type: TimeSelectionType.CalendarYearSingleValueByMonths,
                    input: { start: lastMonthEndOfMonth, end: lastMonthEndOfMonth },
                  },
                },
              ],
            },
            {
              key: 'total-work-hours-managers',
              title: {
                [Languages.EN]: 'Managers',
                [Languages.JA]: '管理監督者',
              },
              queries: [
                {
                  metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0213_2AvgWorkHoursPerPersonAvgWithWh }],
                  timeSelection: {
                    type: TimeSelectionType.CalendarYearSingleValueByMonths,
                    input: { start: lastMonthEndOfMonth, end: lastMonthEndOfMonth },
                  },
                  filters: `EMPLOYEE.CUSTOM_FIELD_3_LEVEL_1 = '管理監督者'`,
                },
              ],
            },
            {
              key: 'total-work-hours-non-managers',
              title: {
                [Languages.EN]: 'Non Managers',
                [Languages.JA]: '上記以外',
              },
              queries: [
                {
                  metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0213_2AvgWorkHoursPerPersonAvgWithWh }],
                  timeSelection: {
                    type: TimeSelectionType.CalendarYearSingleValueByMonths,
                    input: { start: lastMonthEndOfMonth, end: lastMonthEndOfMonth },
                  },
                  filters: `("EMPLOYEE" ."CUSTOM_FIELD_3_LEVEL_1" = ('フレックス月給')) OR ("EMPLOYEE" ."CUSTOM_FIELD_3_LEVEL_1" = ('契約社員(時給)')) OR ("EMPLOYEE" ."CUSTOM_FIELD_3_LEVEL_1" = ('契約社員(月給)')) OR ("EMPLOYEE" ."CUSTOM_FIELD_3_LEVEL_1" = ('役職者(時間管理)')) OR ("EMPLOYEE" ."CUSTOM_FIELD_3_LEVEL_1" = ('社員')) OR ("EMPLOYEE" ."CUSTOM_FIELD_3_LEVEL_1" = ('臨傭(給与振込)')) OR ("EMPLOYEE" ."CUSTOM_FIELD_3_LEVEL_1" IS NULL)`,
                },
              ],
            },
          ],
        },
        {
          chartId: '8',
          title: {
            [Languages.EN]: 'Avg Overtime per Employee',
            [Languages.JA]: '所定外労働時間',
          },
          subtitle: {
            [Languages.EN]: '(Most recent month)',
            [Languages.JA]: '(最新の月間値)',
          },
          chartTypeConfig: { chartType: 'kpi' },
          gridColSpan: 4,
          sections: [
            {
              key: 'overtime',
              queries: [
                {
                  metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0215_2MonthlyOtSumPerHcWithOt }],
                  timeSelection: {
                    type: TimeSelectionType.CalendarYearSingleValueByMonths,
                    input: { start: lastMonthEndOfMonth, end: lastMonthEndOfMonth },
                  },
                },
              ],
            },
          ],
        },
        {
          chartId: '9',
          title: {
            [Languages.EN]: 'Statutory Annual Paid Leave',
            [Languages.JA]: '有給取得日数',
          },
          subtitle: {
            [Languages.EN]: '(FYTD)',
            [Languages.JA]: '(期初からの累計)',
          },
          chartTypeConfig: { chartType: 'kpi' },
          gridColSpan: 4,
          align: 'row',
          sections: [
            {
              key: 'voluntary-attrition-ytd',
              queries: [
                {
                  metrics: [
                    { type: 'RegularMetricId', value: RegularMetricId.Pm0381_2StryAnlPaidLeavenotempCumSumPerHc },
                  ],
                  timeSelection: {
                    type: TimeSelectionType.CalendarYearSingleValueByMonths,
                    input: { start: startOfYear(domainPreferences), end: lastMonthEndOfMonth },
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  ],
});
